<template>
  <div>
    <b-row>
      <b-col md="6" sm="12">
        <media-card v-if="media.length > 0" :media="media[0]"></media-card>
      </b-col>
      <b-col md="6" sm="12">
        <b-row>
          <b-col cols="12">
            <b-card no-body>
            <b-card-body>
              <profile-info :userData="youtubeData.basic" :network="'youtube'"/>
            </b-card-body>
            <b-card-body>
              <b-row class="justify-content-between">
                <b-col  class="d-flex justify-content-start align-items-center mb-1">
                  <b-avatar size="30px" rounded class="mr-1 bg-primary bg-lighten-2">
                    <feather-icon size="15" :icon="'UserIcon'"></feather-icon>
                  </b-avatar>
                  <div class="profile-user-info">
                    <h4 class="mb-0">
                      {{ getFormat(youtubeData.metrics.subscribers_count.value) }}
                    </h4>
                    <b-card-text class="text-muted">{{$t('campaigns.followers')}}</b-card-text>
                  </div>
                </b-col>
                <b-col v-if="this.youtubeData.basic.last_video_time" class="d-flex justify-content-start align-items-center mb-1">
                  <b-avatar size="30px" rounded class="mr-1 bg-success bg-lighten-2">
                    <feather-icon size="15" :icon="'VideoIcon'"></feather-icon>
                  </b-avatar>
                  <div class="profile-user-info">
                    <h4 class="mb-0">
                      {{ timeDate }}
                    </h4>
                    <b-card-text class="text-muted">{{$t('youtube.latestVideos')}}</b-card-text>
                  </div>
                </b-col>
                <b-col v-if="youtubeData.features.blogger_languages.data" class="d-flex justify-content-start align-items-center mb-1">
                  <b-avatar size="30px" rounded class="mr-1  bg-success bg-lighten-2">
                    <feather-icon size="15" :icon="'BookOpenIcon'"></feather-icon>
                  </b-avatar>
                  <div class="profile-user-info">
                    <h5 class="mb-0">
                      {{ languages }}
                    </h5>
                    <b-card-text class="text-muted">{{$t('youtube.idiom')}}</b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="12">
                <statistic-card-horizontal
                  icon="ActivityIcon"
                  color="success"
                  :statistic="cqsText"
                  :statistic-title="$t('youtube.channelQuality')"
                />
              </b-col>
              <b-col cols="12">
                <statistic-card-horizontal
                  icon="EyeIcon"
                  color="success"
                  :statistic="getFormat(youtubeData.metrics.views_count.value)"
                  :statistic-title="$t('youtube.visualizations')"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <statistic-card-with-line-chart
              icon="UsersIcon"
              :statistic="$t('campaigns.followers')"
              :statistic-title="$t('youtube.duringlast')"
              :statisticText="`${this.$t('youtube.total')} : ${getFormat(youtubeData.metrics.subscribers_count.performance.all.value)}`"
              :chart-data="[{name: 'subs',data: seriesSubs}]"
              color="success"
              :heightChart="130"
              :chartOptions="chartOptions"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BCardText, BAvatar,
} from 'bootstrap-vue';
import { getFormat, convertData } from '@/libs/utils/formats';
import { getSeries } from '@/libs/utils/series_graphs';
import { getLanguage } from '@/libs/utils/others';

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    MediaCard: () => import('./MediaCard.vue'),
    ProfileInfo: () => import('../ProfileInfo.vue'),
    StatisticCardHorizontal: () => import('@core/components/statistics-cards/StatisticCardHorizontal.vue'),
    StatisticCardWithLineChart: () => import('@core/components/statistics-cards/StatisticCardWithLineChart.vue')
  },
  props: {
    youtubeData: {
      type: Object,
      required: true
    },
    media: {
      type: Array,
      required: true
    }
  },
  computed: {
    timeDate() {
      const date = new Date(this.youtubeData.basic.last_video_time * 1000)
      return date.toLocaleDateString('es-MX')
    },
    languages() {
      const lang = this.youtubeData.features.blogger_languages.data.map(code => getLanguage(code))
      return lang.reduce((str, name) => `${str},${name}`)
    },
    cqsText() {
      return `${this.youtubeData.features.cqs.data.value} - ${this.$t(`instagram.${this.youtubeData.features.cqs.data.display_mark}`)}`
    },
    seriesSubs() {
      return getSeries(this.quitAll(this.youtubeData.metrics.subscribers_count.performance),'value')
    },
    
  },
  data () {
    return {
      getLanguage,
      getFormat,
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 20,
            right: 20,
            bottom: 30
          },
        },
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['30d', '90d', '180d'],
          show: true,
          labels: {
            show: true,
            offsetY: 0
          }
        },
        colors: ['#A9A2F6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#A9A2F6'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          x: { show: true },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show:true,
          position: 'top'
        }
      },
    }
  },
  methods: {
    quitAll(data) {
      const arr = convertData(data)
      arr.pop()
      return arr
    }
  }
}
</script>
